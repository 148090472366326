/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f1b7aab0-56df-47a6-b8d5-4d8a85ca191d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_F6tEywsef",
    "aws_user_pools_web_client_id": "7h5hl2tij52jl6ud9mesh3rb45",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://vvhnctulcncgdchopq6m65pxvy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xrdrdwb7bjdo7l3nodkpgdvabi",
    "aws_user_files_s3_bucket": "contractor-assets180002-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
