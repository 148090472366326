// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-client-js": () => import("./../../../src/pages/addClient.js" /* webpackChunkName: "component---src-pages-add-client-js" */),
  "component---src-pages-beta-sign-up-js": () => import("./../../../src/pages/betaSignUp.js" /* webpackChunkName: "component---src-pages-beta-sign-up-js" */),
  "component---src-pages-client-form-page-js": () => import("./../../../src/pages/clientFormPage.js" /* webpackChunkName: "component---src-pages-client-form-page-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contractor-site-js": () => import("./../../../src/pages/contractorSite/[...].js" /* webpackChunkName: "component---src-pages-contractor-site-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-quote-calculator-js": () => import("./../../../src/pages/quote-calculator.js" /* webpackChunkName: "component---src-pages-quote-calculator-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

